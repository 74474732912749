import cx from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { useHomepageTabsContext } from "../../contexts/HomepageTabsContext"
import { useSmoothScroll } from "../../hooks/useSmoothScroll"
import { Container } from "../container"
import styles from "./hero.module.css"

export const Hero = () => {
  return <section className={styles.hero}></section>
}
